import React, { useState } from 'react';
import { Button, message, Typography } from 'antd';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import dispatherService from '../../services/dispatherService';
import AssessmentModal from './AssessmentModal';
import AssessmentList from './AssessmentList';
import dataService from '../../services/dataService';
import Task from '../../types/Task';
import integrationService from '../../services/integrationService';
import TaskAssessment from '../../types/TaskAssessment';

const { Title } = Typography;

function Assessment() {
    const [fillingTaskId, setFillingTaskId] = useState<number>();
    const [isModalOpened, setIsModalOpened] = useState(false);

    const { teams, currentTeamId, integrationParams, config } = useSelector((state: AppState) => ({
        integrationParams: state.general.integrationParams,
        teams: state.general.teams,
        currentTeamId: state.general.currentTeamId,
        config: state.general.config,
    }));

    const currentTeam = teams.find(team => team.id === currentTeamId)!;
    const members = currentTeam.members.filter(member => member.isSelected);

    let tasks: Task[] = [];
    currentTeam.task_blocks.forEach(taskBlock => {
        taskBlock.tasks.forEach(task => {
            tasks.push(task);
        });
    });
    tasks = tasks.filter(task => task.isSelected && task.isSelectedSure);

    const handleModalOk = (taskId: number, memberUntiId: number, assessment: number) => {
        dispatherService.setCurrentTeamMemberAssessment(taskId, memberUntiId, assessment);
        dispatherService.selectMemberUntiId(fillingTaskId as number, undefined);
        setFillingTaskId(undefined);
        setIsModalOpened(false);
    };

    const handleModalCancel = () => {
        dispatherService.selectMemberUntiId(fillingTaskId as number, undefined);
        setFillingTaskId(undefined);
        setIsModalOpened(false);
    };

    const getNextTeam = () => {
        let filteredTeams = teams.filter(team => {
            return !team.isFilled && team.id !== currentTeamId;
        });

        return filteredTeams.length > 0 ? filteredTeams[0] : false;
    };

    const isAllTasksFilled = () => {
        return tasks.every(task => task.assessments.length > 0);
    };

    const isAllMembersFilled = () => {
        let allAssessments: TaskAssessment[] = [];
        tasks.forEach(task => {
            task.assessments.forEach(assessment => {
                allAssessments.push(assessment);
            });
        });

        return members.every(member => {
            return allAssessments.find(assessment => assessment.unti_id === member.unti_id);
        });
    };

    const removeAssessment = (taskId: number, memberUntiId: number) => {
        dispatherService.removeCurrentTeamMemberAssessment(taskId, memberUntiId);
    };

    const finish = async () => {
        if (!isAllTasksFilled())
            return message.warning(
                'Вам необходимо поставить хотя бы одну оценку для каждой задачи'
            );

        if (!isAllMembersFilled())
            return message.warning(
                'Вам необходимо поставить хотя бы одну оценку для каждого участника команды'
            );

        const result = dataService.getResult();

        if (integrationParams.silentMode === 'off') {
            await dataService.saveResult();
            await integrationService.sendResult(integrationParams, result);
        }

        let nextTeam = getNextTeam();

        if (nextTeam) {
            dispatherService.setCurrentTeamFilled();
            dispatherService.setStage('selectTeam');
        } else {
            dispatherService.setStage('resultSuccess');
            if (integrationParams.silentMode === 'off') {
                await dataService.saveResult();
                await integrationService.sendEnd(integrationParams);
            }
        }
    };

    return (
        <div className="assessment">
            <Title className="assessment__title" level={3}>
                {config.tasksAssessmentText}
            </Title>
            <Title className="assessment__title" level={5}>
                {config.taskAssessmentText2}
            </Title>
            <AssessmentList
                tasks={tasks}
                members={members}
                setFillingTaskId={setFillingTaskId}
                setIsModalOpened={setIsModalOpened}
                handleModalOk={handleModalOk}
                removeAssessment={removeAssessment}
            />
            {isModalOpened && (
                <AssessmentModal
                    isModalOpened={isModalOpened}
                    handleModalOk={handleModalOk}
                    handleModalCancel={handleModalCancel}
                    taskId={fillingTaskId}
                />
            )}
            <Button className="assessment__button" type="primary" onClick={finish}>
                Завершить
            </Button>
        </div>
    );
}

export default Assessment;
