import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import 'antd/dist/antd.css';
import './css/index.scss';

import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import reducers from './store/reducers';

import storeService from './services/storeService';
import { composeWithDevTools } from 'redux-devtools-extension';
import dispatherService from './services/dispatherService';
import pjson from '../package.json';
import dataService from './services/dataService';

const store: Store = createStore(reducers, {}, composeWithDevTools());

storeService.setStore(store);

const root = document.getElementById('webbot');
dispatherService.setParamsByElement(root);

dataService.loadTeams();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    root
);

//reportWebVitals();

console.log(`%c Team-360 version: ${pjson.version}`, 'background-color: #ffdd2d; color: #333;');
